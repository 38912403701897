.search-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  z-index: 300;
  backdrop-filter: blur(5px);

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow: auto;
}

.search-overlay.hidden {
  display: none;
}

.search-overlay h3 {
  margin-top: 14px;
  margin-bottom: 14px;
  font-size: 24px;
  font-weight: bold;
  opacity: 0.7;
}

.search-overlay input {
  max-width: 400px;
  width: 80%;
  height: 34px;
  border-radius: 5px;
  padding: 8px;
  background-color: rgb(33, 41, 54);
}

.search-overlay .results {
  margin-top: 16px;
  width: 80%;
  max-width: 400px;
}

.search-overlay .search-item {
  position: relative;
  /* width: 100%; */
  height: 94px;
  margin-bottom: 12px;
  border-radius: 5px;
}

.search-overlay .search-item .search-avatar {
  position: absolute;
  width: 100%;
  max-width: 400px;
  height: inherit;
  background-size: cover;
  background-position: center center;
  border-radius: inherit;
}

.search-overlay .search-item .search-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  max-width: 400px;
  background-color: rgba(0, 0, 0, 0.7);
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  justify-content: space-between;
}
/* 
.search-overlay .search-item .search-bar .search-host {

} */
