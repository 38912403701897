@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap");

body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: white;
    overflow: hidden;
    /* overflow-x: hidden; */
    font-family: "Quicksand", sans-serif;
}

.test {
    width: 100%;
    height: 80%;
    position: relative;
    overflow: hidden;
}

.parent-container {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: calc(100vh - 60px);
    overflow: hidden;
}

.interative-container {
    position: relative;
    /* width: 75%; */
    /* background-color: #330000; */
}

.interface-container {
    justify-content: center;
}

.cluster-container {
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.cluster-container table {
    display: block;
    text-align: center;
}

.cluster-container td {
    font-size: 14px;
    padding: 8px;
    width: 128px;
    height: 128px;
}

.seat {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    margin: 0px;
    width: 128px;
    height: 128px;
}

.seat.unavailable {
    background-color: red;
}

.seat:hover {
    transition: all 0.1s;
}

.seat.student .loading-placeholder {
    display: inherit;
    width: inherit;
    height: inherit;
    margin: 0px;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("/public/loading.gif");
    border-radius: inherit;
}

.seat.student .loading-placeholder.notfound {
    background-image: url("/public/notfound.png");
    background-size: cover;
}

.seat.student .loading-placeholder > .profile-image {
    border-radius: inherit;
    display: inherit;
    width: inherit;
    height: inherit;
    background-size: cover;
    background-position: center center;
    margin: 0px;
}

.seat.student.focused {
    transform: scale(1.2);
}

.seat.student.disabled {
    filter: grayscale(100%);
    opacity: 0.5;
}

.login {
    position: absolute;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: bold;
    color: #000;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(2px);
    max-width: 80%;
}

.location {
    font-size: 14px;
}

.location.active {
    text-shadow: 0px 0px 2px black;
}

.page-title {
    margin-top: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 26px;
}

.page-subtitle {
    margin-top: 24px;
    text-transform: uppercase;
    font-size: 18px;
}

.page-subtitle.underlined {
    border-bottom: 1px solid white;
    font-weight: bold;
}

/* ======= RENAME ======= */
.allo,
.allo td,
.allo tr {
    margin: 0px;
    padding: 6px;
    height: fit-content;
    text-align: left;
    /* display: table; */
}

table.allo {
    display: table;
    width: 100%;
}

.allo thead,
.allo thead tr {
    background-color: rgb(33, 41, 54);
}

.allo tr {
    border: 1px solid rgb(40, 50, 66);
    height: 56px;
}

.allo thead td {
    font-size: 16px;
    font-weight: bold;
    height: 66px;
}

.allo tbody td {
    font-size: 14px;
    padding: 10px;
}

.allo tr:nth-child(even) {
    background-color: rgb(13, 18, 29);
}

.text-center {
    text-align: center !important;
}

.w50p {
    width: 50%;
}

.w10p {
    width: 10%;
}
.w5p {
    width: 5%;
}
