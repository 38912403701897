.last-action-mc {
    @media (max-width: 1000px) {
        display: none !important;
    }
}

.group-badges {
    position: absolute;
    left: 0;

    > div {
        padding: 2px;
        margin-bottom: 4px;
        background-color: gray;
        border-radius: 5px;

        width: 18px;
        height: 23px;
        font-size: 14px;
        font-weight: bold;

        text-overflow: clip;
        overflow: hidden;
        word-wrap: break-word;

        transition: width 0.2s ease-in-out;

        &:hover {
            width: 52px;
        }

        &.Tutor {
            background-color: #0078ff;
        }
        &.Foyer {
            background-color: #6ab357;
        }
        &.BDE {
            background-color: #00fff7;
            color: black;
        }

        &.pool {
            display: none;
            background-color: #ffffff;
            color: black;
            // width: 52px;
        }
    }
}

.seat {
    &.pool {
        .profile-image {
            border: 4px solid #ffffff;
        }
    }
    &.not-pool {
        opacity: 0.7;
    }

    .post-cc-badge {
        position: absolute;
        right: 7px;
        top: 4px;
        height: 10px;
        width: 10px;
    }
}

.cluster-container {
    .seat {
        &.inactive-seat {
            background-color: rgb(30, 41, 59);
        }

        &.active-seat {
            background-color: rgb(51, 65, 85);
        }
    }
}
