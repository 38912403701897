.ranking-container {
    position: relative;
    display: block;
    border-radius: 5px;

    margin: 12px;
    margin-top: 14px;
    margin-bottom: 20px;

    height: 78px;
    width: 320px;

    border-bottom: 1px solid rgb(33, 41, 54);

    &.inactive {
        opacity: 0.6;
    }

    &.blackholed {
        opacity: 0.6;
        filter:saturate(0);

        .abosorbed_at {
            display: block;
            position: absolute;
            top: 20px;
            left: 92px;
        }
    }
    .abosorbed_at {
       display: none;
    }
   
}

.min-w320 {
    width: 320px;
}

.ranking-container.first {
    margin-bottom: 24px;
    height: 178px;

    .position {
        display: none;
    }

    .avatar {
        width: 100%;
        height: 176px;
        border-radius: 10px;
    }

    .login-name {
        left: 0px;
        top: 0px;

        background-color: rgba($color: #000000, $alpha: 0.5);
        -moz-backdrop-filter: blur(15px);
        backdrop-filter: blur(15px);

        padding: 0px 10px 5px 10px;
        border-radius: 0px 0px 10px 0px;
        font-weight: bold;
    }

    .data {
        right: 0px;
        bottom: 0px;
        top: auto;
        font-size: 36px;
        // text-shadow: 0px 0px 10px black, 0px 0px 10px black, 0px 0px 10px black;

        background-color: rgba($color: #000000, $alpha: 0.5);
        backdrop-filter: blur(15px);
        -moz-backdrop-filter: blur(15px);
        padding: 5px 10px 0px 10px;
        border-radius: 10px 0px 0px 0px;
    }

    .bar {
        display: none;
    }
}

.avatar {
    position: absolute;
    display: block;
    width: 76px;
    height: 76px;
    background-size: cover;
    background-position: center center;
    border-radius: 10px;
    // background-size: 500%;
}

.position {
    position: absolute;
    top: 38px;
    left: 92px;
}

.login-name {
    position: absolute;
    top: -6px;
    left: 92px;
    font-size: 20px;
    font-weight: bold;
}

.data {
    position: absolute;
    top: -10px;
    right: 0;
    font-size: 26px;
    font-weight: bold;
}

.rank-title {
    margin-top: 24px;
    margin-bottom: 24px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    font-size: 26px;
}

.bar {
    position: absolute;

    top: 60px;
    left: 92px;

    display: block;
    background-color: #1f2b47;
    border-radius: 5px;

    width: calc(100% - 92px);
    height: 12px;
}

.bar .fill {
    height: inherit;
    background-color: #354f8b;
    width: 0%;
    border-radius: inherit;
}

.loading {
    // background-image: url("/public/loading.gif");
    width: 32px;
    height: 32px;
    background-size: contain;
    margin: auto;
}

.pagination-button {
    width: 32px;
    height: 32px;
    border: 1px solid #354f8b;
    // background-color: #1f2b47 !important;
    margin-left: -1px;

    &.active {
        background-color: #354f8b;
    }
    &:hover {
        background-color: #1f2b47 !important;
    }
}


select.pool-select {
    width: 100%;
    height: 32px;
    background-color: #202937;
    font-weight: bold !important;
}