.rc-pagination {
	display: flex;
	margin-top: 12px;
	li {
		width: 24px;
		text-align: center;
		border: 1px solid #354f8b;
		border-radius: 5px;
		color: white;

		&:hover {
			background-color: #354f8b;
			cursor: pointer;
		}

		&.rc-pagination-item-active {
			background-color: #354f8b;
		}
	}
}

.primary-container {
    // background-color: rgb(44, 47, 49);
	// background-color: #262e3a;
	background-color: #101827;
    width: 100vw;
    height: 100vh;

	.secondary-container {
		overflow-x: auto;
		overflow-y: auto;
		height: 100%;
	}
}

// Utilities 
.h-full {
	height: 100%;
}

.flex {
	display: flex;
}

.flex-col {
	flex-direction: column;
}

.flex-row {
	flex-direction: row;
}

.items-center {
	justify-items: center;
}


// THE GOOSE

.goose {
	position: absolute;
	top: 120px;
	left: 40px;
	display: block;
	background-color: white;
	width: 110px;
	height: 50px;
	border-radius: 25px;

	.head {
		position: absolute;
		bottom: 2px;
		left: 2px;
		display: block;
		background-color: #ffffff;
		width: 32px;
		height: 70px;
		border-radius: 25px;

		.eyel {
			position: absolute;
			top: 2px;
			left: 2px;
			background-color: #000000;
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
		}
		.eyer {
			position: absolute;
			top: 2px;
			right: 2px;
			background-color: #000000;
			display: block;
			width: 12px;
			height: 12px;
			border-radius: 50%;
		}
	}
}

// =============


// @media (prefers-color-scheme: light) {
// 	.cluster-container {
// 		.seat {
// 			&.inactive-seat {
// 				background-color: rgb(205, 205, 205);
// 			}
		
// 			&.active-seat {
// 				background-color: rgb(255, 255, 255);
// 			}
// 		}
// 	}

// 	.primary-container {
// 		background-color:rgb(255, 255, 255);
// 	}
// }